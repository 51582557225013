<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <h4 id="traffic" class="card-title mb-0">List All Visitor</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="4" class="col align-self-center row justify-content-end">
            <CButton
							class="my-2 text-center"
							color="danger"
							variant="outline"
							square
							size="sm"
							@click="generatePdf"
						>
							Generate PDF
						</CButton> &nbsp;
				    <download-excel
              class="btn btn-default"
              :data="filteredItems"
              :fields="jsonFields"
              worksheet="Sheet1"
              name="list-all-visitors.xls"
            >
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
              >
                Download Excel
              </CButton>
            </download-excel>
			    </CCol>
        </CRow>

        <CRow class="mt-5">
          <CCol sm="3">
						<CInput
							type="date"
							label="Date From"
							placeholder=""
							description="Masukkan tanggal awal pencarian."
							v-model="start_date"
							required
						/>
					</CCol>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date To"
							placeholder=""
							description="Masukkan tanggal akhir pencarian."
							v-model="end_date"
							required
						/>
					</CCol>
          <CCol sm="2">
						<CInput
							type="text"
							label="Search.."
							placeholder=""
							description="Masukkan kata kunci pencarian."
							v-model="search"
							required
						/>
					</CCol>
					<CCol sm="2">
            <CSelect 
							:value.sync="keyword"
							name="keyword"
							label="Filter By"
							:options="keywords"
						/>
          </CCol>
          <CCol sm="2">
            <CButton
              style="margin-top: 30px;"
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="filterRange"
            >
              Apply
            </CButton> &nbsp;
            <CButton
              style="margin-top: 30px;"
              color="success"
              variant="outline"
              square
              size="sm"
              @click="filterRefresh"
            >
              Refresh List
            </CButton>
          </CCol>
        </CRow>

        <CDataTable
					hover
					striped
					border
					small
					sorter
					:items="filteredItems"
					:fields="fields"
          :items-per-page="perPage"
				  pagination
					:loading="isLoading"
				>
					<template #id="{item}">
						<td align="center">{{filteredItems.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
					</template>
          <template #start_date="{item}">
            <td align="right">{{ date(item.start_date, 'DD MMM YYYY') }}</td>
          </template>
          <template #check_in="{item}">
            <td align="right">{{item.check_in === null ? '' : date(item.check_in, 'DD MMM YYYY HH:mm')}}</td>
          </template>
          <template #check_out="{item}">
            <td align="right">{{item.check_out === null ? '' : date(item.check_out, 'DD MMM YYYY HH:mm')}}</td>
          </template>
          <template #days="{item}">
            <td align="right">{{+item.days + 1}} hari</td>
          </template>
          <template #status="{item}">
            <td>{{item.status === 'Cancel' ? 'Canceled' : item.status}}</td>
          </template>
				</CDataTable>

        <vue-html2pdf
					:show-layout="true"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					filename="Lintasarta-List-Visitor"
					:pdf-quality="2"
					:manual-pagination="true"
					pdf-format="a4"
					pdf-orientation="portrait"
					pdf-content-width="100%"
					@hasStartedGeneration="hasStartedGeneration()"
					@hasGenerated="hasGenerated($event)"
					:html-to-pdf-options="htmlToPdfOptions"
					ref="html2Pdf"
					class="d-none"
				>
          <section slot="pdf-content" id="content">
            <ThePdfHeader title="Report List All Visitor" :reportFrom="date(new Date(), 'DD MMMM YYYY')" :reportTo="date(new Date(), 'DD MMMM YYYY')" :reportFor="reportFor" />

            <CDataTable
					    border
					    small
					    :items="filteredItems"
					    :fields="fields"
              :items-per-page="perPage"
				    >
					    <template #id="{item}">
						    <td align="center">{{filteredItems.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
					    </template>
              <template #start_date="{item}">
                <td align="right">{{ date(item.start_date, 'DD MMM YYYY') }}</td>
              </template>
              <template #check_in="{item}">
                <td align="right">{{item.check_in === null ? '' : date(item.check_in, 'DD MMM YYYY HH:mm')}}</td>
              </template>
              <template #check_out="{item}">
                <td align="right">{{item.check_out === null ? '' : date(item.check_out, 'DD MMM YYYY HH:mm')}}</td>
              </template>
              <template #days="{item}">
                <td align="right">{{+item.days + 1}} hari</td>
              </template>
              <template #status="{item}">
                <td>{{item.status === 'Cancel' ? 'Canceled' : item.status}}</td>
              </template>
				    </CDataTable>
          </section>
        </vue-html2pdf>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import http from '@/utils/http-common';

import VueHtml2pdf from 'vue-html2pdf';
import ThePdfHeader from '@/containers/ThePdfHeader.vue';

export default {
  components: {
    VueHtml2pdf, ThePdfHeader
  },
  data () {
    return {
      company_id: +localStorage.getItem('company_id'),
      role: +localStorage.getItem('role'),
      items: [],
      search: '',
      filteredItems: [],
      fields: [
        { key: 'id', label: 'No' },
        'visit_id',
        { key: 'start_date', label: 'Visit Date' },
        { key: 'company_name', label: 'Company' },
        'name',
        'email',
        'role',
        { key: 'site_name', label: 'Data Center' },
        { key: 'room_id', label: 'Room' },
        { key: 'activity_name', label: 'Activity' },
        { key: 'days', label: 'Duration' },
        { key: 'check_in', label: 'Check In' },
        { key: 'check_out', label: 'Check Out' },
        'status'
      ],
      currentPage: 1,
			perPage: 15,
			totalRows: 1,
			isLoading: true,
      start_date: '',
      end_date: this.date2Str(new Date(), 'yyyy-MM-dd'),
      keyword: 'Company Name',
      keywords: [
        'Company Name', 'Name', 'Role', 'Data Center', 'Activity', 'Status', 
      ],
      reportFor: 'Company Name: All',
      jsonFields: {
        No: {
          field: "id",
          callback: (value) => {
            return this.filteredItems.map(function(x) {return x.id; }).indexOf(value)+1;
          },
        },
        "Visit ID": 'visit_id',
        "Visit Date": 'start_date',
        "Company Name": 'company_name',
        "Name": 'name',
        "Email": 'email',
        "Role": 'role',
        "Data Center": 'site_name',
        "Rooms": 'room_id',
        "Activity": 'activity_name',
        "Duration": {
          field: "days",
          callback: (value) => {
            return +value + 1;
          },
        },
        "Check In": {
          field: "check_in",
          callback: (value) => {
            return this.date(value, 'DD MMMM YYYY HH:mm');
          },
        },
        "Check Out": {
          field: "check_out",
          callback: (value) => {
            return this.date(value, 'DD MMMM YYYY HH:mm');
          },
        },
        "Status": 'status',
      },
      htmlToPdfOptions: {
				margin: 0,
				filename: 'Lintasarta-List-Visitor',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
    }
  },
  methods: {
    generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    date2Str(x, y) {
    	const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    	};
    	y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    	});

    	return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    	});
		},
    lastDays() {
      const dt = new Date();
      dt.setDate(dt.getDate() - 30);
      this.start_date = this.date2Str(dt, 'yyyy-MM-dd');
    },
    getVisits() {
      let self = this;
      return http.get('/report/list-visitors')
			  .then(function (response) {
				  let data = response.data.data.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0));

          /* if (self.role === 4 || self.role > 5) {
            data = data.filter(function(item) {
    				  return item.company_id === self.company_id;  
					  });
          } */

          console.log(data)
          
          self.items = data;
          self.filteredItems = data;
          self.isLoading = false;
			  }).catch(function (error) {
				  console.log(error);
			  });
    },
    filterRange() {
      let self = this;
      self.filteredItems = [];

      let start = new Date(self.start_date).getTime();
      let end = new Date(self.end_date).getTime();

      let data = self.items.filter(item => {
        let date = new Date(item.start_date).getTime();
        return date >= start && date <= end;
      })

      data = data.filter(item => {
        if (self.keyword === 'Role') {
          self.reportFor='Role Name: ' + self.search.toLowerCase();
          return item.role.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Data Center') {
          self.reportFor='Data Center: ' + self.search.toLowerCase();
          return item.site_name.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Company Name') {
          self.reportFor='Company Name: ' + self.search.toLowerCase();
          return item.company_name.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Activity') {
          self.reportFor='Activity Name: ' + self.search.toLowerCase();
          return item.activity_name.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Status') {
          self.reportFor='Status: ' + self.search.toLowerCase();
          return item.status.toLowerCase().includes(self.search.toLowerCase());
        } else {
          self.reportFor='Name: ' + self.search.toLowerCase();
          return item.name.toLowerCase().includes(self.search.toLowerCase());
        } 
      })

      // console.log(data)

      self.filteredItems = data;
    },
    filterRefresh() {
      this.filteredItems = this.items;
      this.reportFor= 'Company Name: All';
      this.search = '';
      this.end_date = this.date2Str(new Date(), 'yyyy-MM-dd');
      this.lastDays();
    },
  },
  mounted() {
    this.lastDays();
		this.getVisits();
	}
}
</script>

<style scoped>
#content {
  background: #fff;
  font-size: 5px;
  padding: 12px;
}
</style>
