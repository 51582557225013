<template>
  <div>
    <CRow class="my-3">
			<CCol md="3" class="m-auto" >
				<CCol md="12">No. Dok&nbsp;:</CCol>
				<CCol md="12">Versi&nbsp;&nbsp;&nbsp;:</CCol>
				<CCol md="12">Label&nbsp;&nbsp;&nbsp;:</CCol>
			</CCol>
			<CCol md="6" class="text-center">
				<h5>{{title}}</h5>
			</CCol>
			<CCol md="3" class="text-center">
				<img src="@/assets/logo.png" style="height: 70px;" class="my-auto ml-auto mr-5" />
			</CCol>
		</CRow>
    <CRow class="mt-3">
      <CCol md="4" class="">
        <p>{{reportFor}}</p>
      </CCol>
      <CCol md="4" class="text-center">
        <p>Report Periode From <strong>{{reportFrom}}</strong> To <strong>{{reportTo}}</strong></p>
      </CCol>
      <CCol md="4" class="text-center">
        <p>Request by: <strong>{{user.user.name}}</strong></p>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ThePdfHeader',
  computed: {
    ...mapState(['user'])
  },
  props: {
    title: {
      default: '',
      type: String,
      required: true
    },
    reportFrom: {
      default: '',
      type: String
    },
    reportTo: {
      default: false,
      type: String
    },
    reportFor: {
      default: '',
      type: String
    },
  },
}
</script>